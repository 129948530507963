export class Handbook {
  pageTitle = ''
  name = ''
  vuexModuleNamespaced = ''
  link = '/admin-panel/handbook/'

  constructor ({ pageTitle, name, vuexModuleNamespaced, url }) {
    this.pageTitle = pageTitle
    this.name = name
    this.vuexModuleNamespaced = vuexModuleNamespaced || name
    this.link = url || this.link + this.name
  }
}
