<template>
  <BaseModal
    :title="modalTitle"
    @close="onClose"
  >
    <VForm
      :value="state.formData"
      class="gap-m"
      @request="request"
    >
      <VInput
        label="Название:"
        name="name"
        required
      >
        <Validation for="required"/>
      </VInput>

      <VSelect
        v-if="inputs.types"
        label="Активность только для партий:"
        name="types"
        :options="$store.state.records.properties.statuses_types"
        multiple
        isTooltip
        tooltipText="Этап будет активен только для выбранных тут этапов, не выбранные партии будут перепрыгивать этот этап. Если этап нужен для всех партий, то оставьте поле пустым"
      />

      <VInput
        v-if="inputs.vendorCode"
        label="Артикул:"
        name="vendorCode"
        required
      >
        <Validation for="required"/>
      </VInput>

      <VInput
        v-if="inputs.performance"
        label="Производительность (шт/мин):"
        name="performance"
        required
        :pattern="patternNum100"
      >
        <Validation for="required"/>
        <Validation for="pattern" key-text="num100"/>
      </VInput>

      <VInput
        v-if="inputs.startTime"
        label="Пусконаладка (час):"
        name="startTime"
        required
        :pattern="patternNum100"
      >
        <Validation for="required"/>
        <Validation for="pattern" key-text="num100"/>
      </VInput>

      <VSelect
        v-if="inputs.coating"
        label="Тип покрытия"
        name="coating"
        :options="$store.state.records.properties.coating"
        searchable
      />

      <VSelect
        v-if="inputs.workshop"
        label="Цех производства"
        name="workshop"
        :options="$store.state.records.properties.workshop"
        multiple
      />

      <VSelect
        v-if="inputs.material"
        label="Материал"
        name="material"
        :options="$store.state.records.properties.material"
        multiple
      />

      <VInput
        v-if="inputs.hardness"
        label="Твердость:"
        name="hardness"
      />

      <VInput
        v-if="inputs.density"
        label="Плотность:"
        name="density"
        required
      >
        <Validation for="required"/>
      </VInput>

      <VSelect
        v-if="inputs.surface_treatment"
        label="Поверхностная обработка"
        name="surface_treatment"
        :options="$store.state.records.properties.surface_treatment"
      />

      <VButton
        text="Сохранить"
        type="submit"
        class="m-center"
        color="primary"
        size="xl"
        :loading="$store.state.isSendingRequestModal"
      />
    </VForm>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/ui/Modal/BaseModal'
import VForm from '@/components/Form/VForm'
import Validation from '@/components/Form/Validation'
import VButton from '@/components/simple/button/VButton'
import VInput from '@/components/Form/Vinput/VInput'
import { PATTERN_LIST } from '@/const/validation'
import VSelect from '@/components/Form/VSelect.vue'

export default {
  name: 'ModalHandbook',
  components: {
    VSelect,
    BaseModal,
    VForm,
    Validation,
    VButton,
    VInput
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    inputs: {
      type: Object,
      default: () => ({})
    },
    state: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    modalTitle () {
      return `${this.modalAction} ${this.title}`
    },
    modalAction () {
      return this.state.updateId ? 'Редактировать' : 'Создать'
    },
    patternNum100 () {
      return PATTERN_LIST.num100
    }
  },
  methods: {
    request (data) {
      this.$emit('request', data)
    },
    onClose () {
      this.$emit('close')
    }
  }
}
</script>
