<template>
  <div class="spinner-overlay absolute-full">
    <Spinner />
  </div>
</template>

<script>
export default {
  name: 'SpinnerOverlay'
}
</script>

<style lang="scss">
  .spinner-overlay {
    z-index: 1;
    border-radius: inherit;

    .spinner-wrap {
      position: fixed;
      transform: translateY(100px);
      width: calc(100% - 260px);
    }

    &--catalog {
      .spinner-wrap {
        width: calc(100% - 380px);
      }
    }
  }

</style>
