<template>
  <div class="ap-card">
    <div class="between ai-c gap-s">
      <h3
        class="ap-card__title"
        :class="{ 'has-detail': hasDetail }"
        @click="onTitle"
      >
        {{ title }}
      </h3>

      <div class="buttons gap-s">
        <VButton
          size="sm"
          icon="remove"
          @click.native="onRemove"
        />

        <VButton
          size="sm"
          icon="edit"
          @click.native="onUpdate"
        />
      </div>
    </div>

    <slot />
  </div>
</template>

<script>
import VButton from '@/components/simple/button/VButton'

export default {
  // AP - Admin Panel
  name: 'APCard',
  components: { VButton },
  props: {
    title: {
      type: String,
      required: true
    },
    hasDetail: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onTitle () {
      this.$emit('open-detail')
    },
    onUpdate () {
      this.$emit('update')
    },
    onRemove () {
      this.$emit('remove')
    }
  }
}
</script>

<style lang="scss" scoped>
.ap-card {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  max-width: 1480px;
  background: $color__background--card;
  border: 1px solid $color__background--main;
  border-radius: 20px;
  padding: 30px;
  box-shadow: $color__card-box-shadow;

  @media screen and (max-width: $desktop-width-s) {
    padding: 20px 20px 30px;
    max-width: 980px;
  }

  @media screen and (max-width: $tablet-width) {
    max-width: 640px;
  }

  &__title.has-detail {
    cursor: pointer;
    transition: $transition;

    @media screen and (min-width: $hover-enable) {
      &:hover {
        color: $color__main;
      }
    }
  }
}
</style>
